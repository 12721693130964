<template>
  <PageCard pageName="Create User" :iconClass="['fa', 'fa-user-plus', 'fa-lg']">
    <template slot="page_content">
      <div class="row">
        <div class="col-sm-12 col-lg-8">
          <b-form-group
            label="First, Last Name"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
          >
            <div class="row">
              <div class="col-sm-6">
                <b-form-input
                  id="name"
                  type="text"
                  placeholder="First Name"
                  v-model="user.first_name"
                ></b-form-input>
              </div>
              <div class="col-sm-6">
                <b-form-input
                  id="name"
                  type="text"
                  placeholder="Last Name"
                  v-model="user.last_name"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>

          <b-form-group
            label="Email"
            label-for="email"
            :label-cols="3"
            :horizontal="true"
          >
            <div class="row">
              <div class="col-sm-6">
                <b-form-input
                  id="email"
                  type="email"
                  placeholder="Email"
                  v-model="user.email"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>

          <b-form-group
            label="Company"
            label-for="company"
            :label-cols="3"
            :horizontal="true"
          >
            <div class="row">
              <div class="col-sm-6">
                <b-form-input
                  id="company"
                  type="text"
                  placeholder="Company"
                  v-model="user.company"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>

          <b-form-group
            label="Title"
            label-for="title"
            :label-cols="3"
            :horizontal="true"
          >
            <div class="row">
              <div class="col-sm-6">
                <b-form-input
                  id="title"
                  type="text"
                  placeholder="Title"
                  v-model="user.title"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>

          <b-form-group
            label="Phone"
            label-for="phone"
            :label-cols="3"
            :horizontal="true"
          >
            <div class="row">
              <div class="col-sm-6">
                <b-form-input
                  id="phone"
                  type="text"
                  placeholder="(123)-456-7890"
                  v-model="user.phone"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>

          <b-form-group
            label="User Role"
            label-for="user_role"
            :label-cols="3"
            :horizontal="true"
          >
            <div class="row">
              <div class="col-sm-6">
                <b-form-select
                  id="user_role"
                  v-model="user.role_id"
                  text-field="name"
                  value-field="id"
                  :options="roles"
                >
                </b-form-select>
              </div>
            </div>
          </b-form-group>

          <br />

          <h5>User Groups</h5>

          <b-card class="reports-card" body-class="p-1 pt-3">
            <div class="row align-items-center">
              <div class="col-sm-5 pr-0">
                <h6>Available Groups</h6>
                <b-card class="std-border mb-0 group-sub-card" body-class="p-1">
                  <b-list-group v-if="!this.groups.length" class="text-center">
                    <b-list-group-item
                      ><i>No Groups found ... </i></b-list-group-item
                    >
                  </b-list-group>
                  <b-list-group v-else>
                    <div v-for="(group, index) in availableGroups" :key="index">
                      <b-list-group-item
                        class="p-1"
                        :class="[
                          { selected: group.id === selectedGroup.id },
                          'p-1'
                        ]"
                        @click="selectGroup(group)"
                      >
                        {{ group.name }}
                      </b-list-group-item>
                    </div>
                  </b-list-group>
                </b-card>
              </div>
              <div class="col-sm-2">
                <div class="group-control">
                  <div class="row text-center">
                    <div class="col-sm-12">
                      <b-button
                        size="sm"
                        variant="primary"
                        class="font-weight-bold"
                        :disabled="!selectedGroup.id"
                        @click="addGroup()"
                        v-html="'&nbsp;&nbsp;&#187;&nbsp;&nbsp;'"
                      ></b-button>
                    </div>
                  </div>
                  <div class="row text-center pt-1">
                    <div class="col-sm-12">
                      <b-button
                        size="sm"
                        variant="secondary"
                        class="font-weight-bold"
                        :disabled="!selectedGroup.id"
                        @click="removeGroup()"
                        v-html="'&nbsp;&nbsp;&#171;&nbsp;&nbsp;'"
                      ></b-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-5 pl-0">
                <h6>Selected Groups</h6>
                <b-card class="std-border mb-0 group-sub-card" body-class="p-1">
                  <b-list-group
                    v-if="!this.selectedGroups.length"
                    class="text-center"
                  >
                    <b-list-group-item
                      ><i>No Groups selected ... </i></b-list-group-item
                    >
                  </b-list-group>
                  <b-list-group v-else>
                    <div v-for="(group, index) in selectedGroups" :key="index">
                      <b-list-group-item
                        class="p-1"
                        :class="[
                          { selected: group.id === selectedGroup.id },
                          'p-1'
                        ]"
                        @click="selectGroup(group)"
                      >
                        {{ group.name }}
                      </b-list-group-item>
                    </div>
                  </b-list-group>
                </b-card>
              </div>
            </div>
          </b-card>
        </div>
      </div>
      <div slot="footer">
        <b-row class="">
          <b-col md="12">
            <router-link
              tag="button"
              :to="{ path: '/admin/users' }"
              class="btn btn-secondary fw-btn mt-3 mr-3"
            >
              Cancel
            </router-link>
            <b-button
              type="submit"
              variant="primary"
              class="btn btn-primary fw-btn mt-3 mx-3"
              @click="createUser"
              >Create User</b-button
            >
          </b-col>
        </b-row>
      </div>
    </template>
  </PageCard>
</template>

<script>
import PageCard from '@/modules/core/components/layouts/PageCard'

export default {
  name: 'CreateUser',
  components: {
    PageCard
  },
  created() {
    this.$http
      .get('/api/group_users/new', {
        handleErrors: true
      })
      .then(res => {
        res.data.groups.forEach(group => {
          group.available = true
          group.selected = false
        })
        this.groups = res.data.groups
        this.roles = res.data.roles
      })
  },
  data() {
    return {
      roles: [],
      groups: [],
      selectedGroup: {},
      user: {
        first_name: null,
        last_name: null,
        email: null,
        company: null,
        title: null,
        phone: null,
        role_id: null
      }
    }
  },
  computed: {
    availableGroups() {
      return this.groups.filter(group => group.available === true)
    },
    selectedGroups() {
      return this.groups.filter(group => group.selected === true)
    }
  },
  methods: {
    addGroup() {
      let selectedGroup = this.groups.find(
        group => group.id === this.selectedGroup.id
      )
      selectedGroup.available = false
      selectedGroup.selected = true
    },
    removeGroup() {
      let selectedGroup = this.groups.find(
        group => group.id === this.selectedGroup.id
      )
      selectedGroup.available = true
      selectedGroup.selected = false
    },
    selectGroup(group) {
      this.selectedGroup = group
    },
    createUser() {
      this.$http.post('/api/group_users', this.user, {
        handleErrors: true
      })
    }
  },
  watch: {
    selectedGroups: function(newVal) {
      this.user.group_ids = newVal.map(group => group.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.group-sub-card {
  height: 13rem;
  overflow-y: auto;
}

.list-group-item {
  border: none;
  cursor: pointer;

  &.selected {
    background-color: #f0f3f5;
  }
}
</style>
